import { Heading, IconButton, InputField } from 'modules/app';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { quickSearchAction } from 'modules/quickSearch';
import { useDispatch } from 'react-redux';

import * as styles from 'modules/quickSearch/styles/Root.styles';
import SearchIcon from 'assets/icons/search.svg';

interface Props {
  searchValue?: string;
}

export const NoResult: React.FC<Props> = ({ searchValue }) => {
  const dispatch = useDispatch();
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: { search: string }) => {
    dispatch(quickSearchAction.searchValue(data.search));
  };

  return (
    <div css={styles.noResult}>
      {searchValue ? (
        <>
          <Heading as="h1" size="fluidXxxLarge" css={styles.heading}>
            <FormattedMessage id="search.noResult" />
            <br />"{searchValue}"
          </Heading>
          <p>
            <FormattedMessage id="search.noResultContent" />
          </p>
        </>
      ) : (
        <p>
          <FormattedMessage id="search.noSearch" />
        </p>
      )}

      <FormProvider {...methods}>
        <form css={styles.noResultForm} onSubmit={handleSubmit(onSubmit)}>
          <InputField
            name="search"
            value=""
            label="search.noResultPlaceholder"
            constraints={{
              required: true,
              maxLength: 40,
            }}
          />

          <IconButton
            size="tiny"
            buttonType="ghost"
            type="submit"
            icon={<SearchIcon />}
          />
        </form>
      </FormProvider>
    </div>
  );
};
