import { AppState } from 'modules/redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { useHttpClient } from 'modules/app';
import { useEffect, useState } from 'react';
import { HttpError } from 'modules/app/http/client/httpError';
import { quickSearchAction } from '../redux';

export const useQuickSearchResults = () => {
  const dispatch = useDispatch();
  const { searchValue, openings, companies } = useSelector(
    (state: AppState) => state.quickSearch,
  );
  const { basics } = useSelector((state: AppState) => state.user);

  const [openingsPage, setOpeningsPage] = useState(openings.page);
  const [companiesPage, setCompaniesPage] = useState(companies.page);

  const http = useHttpClient('/api/v1');

  async function getCompanies(page: number) {
    try {
      const { data } = await http.get<CompanySearchResponse>(
        'companies/quick-search',
        {
          headers: {
            'x-location-latitude': basics?.location?.x,
            'x-location-longitude': basics?.location?.y,
          },
          params: {
            search: searchValue,
            page,
            perPage: 6,
          },
        },
      );

      dispatch(
        quickSearchAction.companies({
          companies: data.data,
          page: data.page,
          lastPage: data.last_page,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(quickSearchAction.companiesError(e));
    }
  }

  async function getOpenings(page: number) {
    try {
      const { data } = await http.get<OpeningsSearchResponse>(
        'company/openings/quick-search',
        {
          headers: {
            'x-location-latitude': basics?.location?.x,
            'x-location-longitude': basics?.location?.y,
          },
          params: {
            search: searchValue,
            page,
            per_page: 6,
          },
        },
      );

      dispatch(
        quickSearchAction.openings({
          openings: data.data,
          page: data.page,
          lastPage: data.last_page,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(quickSearchAction.openingsError(e));
    }
  }

  useEffect(() => {
    getOpenings(openingsPage);
  }, [openingsPage, searchValue]);

  useEffect(() => {
    getCompanies(companiesPage);
  }, [companiesPage, searchValue]);

  useEffect(() => {
    setOpeningsPage(1);
    setCompaniesPage(1);
  }, [searchValue]);

  return {
    searchValue,
    openingsPage,
    companiesPage,
    setOpeningsPage,
    setCompaniesPage,
  };
};
