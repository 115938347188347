import { Heading, MainContent, Skeleton } from 'modules/app';
import React from 'react';
import { useQuickSearchResults } from './hooks';

import * as styles from 'modules/quickSearch/styles/Root.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { RouteComponentProps } from '@reach/router';
import { motion } from 'framer-motion';
import { Companies, Openings, NoResult } from './components';

export const QuickSearch: React.FC<RouteComponentProps> = () => {
  const { companies, openings, searchValue } = useSelector(
    (state: AppState) => state.quickSearch,
  );
  const { openingsPage, companiesPage, setOpeningsPage, setCompaniesPage } =
    useQuickSearchResults();
  const isLoading = openings.isLoading && companies.isLoading;

  const onOpeningsChange = (data: { selected: number }) => {
    setOpeningsPage(data.selected + 1);
  };

  const onCompaniesChange = (data: { selected: number }) => {
    setCompaniesPage(data.selected + 1);
  };

  if (isLoading) {
    return (
      <MainContent>
        <div css={styles.content}>
          <Skeleton
            count={6}
            containerStyles={styles.openings}
            customStyles={styles.openingSkeletor}
          />
        </div>
        <div css={styles.content}>
          <Skeleton count={6} customStyles={styles.companiesSkeleton} />
        </div>
      </MainContent>
    );
  }

  if (
    !Boolean(openings.openings?.length) &&
    !Boolean(companies.companies?.length)
  ) {
    return <NoResult searchValue={searchValue} />;
  }

  return (
    <MainContent>
      {Boolean(openings.openings?.length) && (
        <motion.section layout>
          <motion.div layout="position" css={styles.content}>
            <Heading
              as="h1"
              size="fluidXxxLarge"
              useMotion
              css={styles.heading}
              layout="position"
            >
              <FormattedMessage id="search.openingsHeader" />
              <br />"{searchValue}"
            </Heading>

            <Openings
              {...openings}
              page={openingsPage}
              onPageChange={onOpeningsChange}
            />
          </motion.div>
        </motion.section>
      )}

      {Boolean(companies.companies?.length) && (
        <motion.section layout>
          <motion.div layout="position" css={styles.content}>
            <Heading
              as="h1"
              size="fluidXxxLarge"
              css={styles.heading}
              useMotion
              layout="position"
            >
              <FormattedMessage id="search.companiesHeader" />
              <br />"{searchValue}"
            </Heading>

            <Companies
              {...companies}
              page={companiesPage}
              onPageChange={onCompaniesChange}
            />
          </motion.div>
        </motion.section>
      )}
    </MainContent>
  );
};
