import React from 'react';
import { Router } from '@reach/router';
import { PageProps } from 'gatsby';
import Seo from 'modules/app/components/Seo';
import { AppLayout } from 'modules/app';
import { QuickSearch } from 'modules/quickSearch/QuickSearch';
import { NotFound } from 'modules/notFound';

const Search: React.FC<PageProps> = () => {
  return (
    <>
      <Seo title="Search - Roango" />

      <AppLayout isPublic>
        <Router basepath="/:lang/search">
          <QuickSearch path="/quick-search" />
          <NotFound default type="contentOnlyCenter" />
        </Router>
      </AppLayout>
    </>
  );
};

export default Search;
