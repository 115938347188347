import React from 'react';
import { HttpError, OpeningBox, Skeleton } from 'modules/app';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import * as styles from 'modules/quickSearch/styles/Root.styles';

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

interface Props {
  isLoading: boolean;
  error?: ApiErrorData;
  openings?: OpeningsSearch[];
  lastPage: number;
  page: number;
  onPageChange: (data: { selected: number }) => void;
}

export const Openings: React.FC<Props> = ({
  isLoading,
  error,
  openings,
  lastPage,
  page,
  onPageChange,
}) => {
  if (error) {
    return <HttpError error={error} />;
  }

  return (
    <>
      {isLoading ? (
        <Skeleton
          count={6}
          containerStyles={styles.openings}
          customStyles={styles.openingSkeletor}
        />
      ) : (
        <motion.div
          key="openings"
          layout="position"
          {...FADE_IN_MOVE_Y_REGULAR}
          transition={{ duration: 0.4 }}
        >
          {openings && (
            <div css={styles.openings}>
              {openings.map((opening) => (
                <OpeningBox opening={opening} key={opening.slug} />
              ))}
            </div>
          )}
        </motion.div>
      )}

      {lastPage > 1 && (
        <React.Suspense fallback={null}>
          <motion.div layout="position">
            <Pagination
              initialPage={page - 1}
              pageCount={lastPage}
              onPageChange={onPageChange}
            />
          </motion.div>
        </React.Suspense>
      )}
    </>
  );
};
