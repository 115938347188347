import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  padding-bottom: calc(var(--unit) * 7);
  margin-bottom: calc(var(--unit) * 7);
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
  width: 100%;
  display: grid;
  grid-gap: calc(var(--unit) * 2);

  @media ${breakpoints.medium} {
    grid-gap: calc(var(--unit) * 8);
    grid-template-columns: 7fr 5fr;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const name = css`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    margin-left: 12px;
  }

  &:hover {
    cursor: pointer;

    p {
      text-decoration: underline;
    }
  }
`;

export const avatarImage = css`
  width: 48px;
  height: 48px;
  min-width: 48px;
`;

export const avatarNoImage = css`
  ${avatarImage};
  background: hsl(var(--color-error-7));

  span {
    color: white;
    ${font.size.base}
  }
`;

export const content = css`
  display: flex;
  padding-left: ${spacing(60)};

  @media ${breakpoints.medium} {
    padding-left: 0;
  }

  p {
    margin: 0;
    margin-right: calc(var(--unit) * 8);
    color: hsl(var(--color-grayscale-6));

    &:first-of-type {
      font-weight: bold;
    }
  }
`;
