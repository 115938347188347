import { motion } from 'framer-motion';
import React from 'react';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

import * as styles from 'modules/quickSearch/styles/Company.styles';
import { useCompanyDescriptionValues, Avatar } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { navigate } from 'gatsby';

interface Props {
  company: CompanySearch;
}

export const Company: React.FC<Props> = ({ company }) => {
  const { sizeValues } = useCompanyDescriptionValues();
  const size = sizeValues.find((value) => value.value === company.size);
  const avatarStyles = company.iconPicture
    ? styles.avatarImage
    : styles.avatarNoImage;

  return (
    <motion.div
      css={styles.root}
      {...FADE_IN_MOVE_Y_REGULAR}
      transition={{ duration: 0.3 }}
    >
      <div
        css={styles.name}
        onClick={() => navigate(`/company/${company.handle}`)}
      >
        <Avatar
          customStyles={avatarStyles}
          name={company.name}
          imagePath={company.iconPicture?.large}
        />
        <p>{company.name}</p>
      </div>
      <div css={styles.content}>
        <p>
          <FormattedMessage
            id="search.openingsCount"
            values={{
              number: company.companyOpeningsCount.toString(),
            }}
          />
        </p>
        <p>{company.address}</p>
        {size && <p>{size.label}</p>}
      </div>
    </motion.div>
  );
};
