import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils/grid';

export const content = css`
  padding: calc(var(--unit) * 15) 0;

  @media ${breakpoints.large} {
    padding: calc(var(--unit) * 30) 0;
  }
`;

export const label = css`
  text-transform: uppercase;
  color: hsl(var(--color-grayscale-6));
  margin: 0;
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
`;

export const heading = css`
  margin-bottom: calc(var(--unit) * 20);
`;

export const noResult = css`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: calc(var(--unit) * 30) 0;

  h1 {
    margin-bottom: calc(var(--unit) * 20);
  }

  p {
    max-width: 416px;
    margin: 0;
    color: hsl(var(--color-grayscale-6));
    margin-bottom: calc(var(--unit) * 10);
  }
`;

export const noResultForm = css`
  width: 100%;
  max-width: 416px;
  margin-top: calc(var(--unit) * 5);
  position: relative;

  input {
    padding-right: calc(var(--unit) * 10);
  }

  button {
    position: absolute;
    right: 0;
    top: 24px;
  }
`;

export const openings = css`
  display: grid;
  grid-gap: calc(var(--unit) * 8);

  @media ${breakpoints.medium} {
    ${grid.template.cols2};
  }

  @media ${breakpoints.large} {
    ${grid.template.cols3};
  }
`;

export const openingSkeletor = css`
  min-height: ${spacing(364)};
`;

export const companiesSkeleton = css`
  min-height: ${spacing(76)};
  margin-bottom: ${spacing(28)};
`;
